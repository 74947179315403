import React from "react"

import { labels, localeUrls } from "../langs/langs"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PartnersSlider from "../components/PartnersSlider"
import IntroBanner from "../components/IntroBanner"
import SocialSlider from "../components/SocialSlider"
import { rSVG } from "../components/ArrowLink"
import OpeningHours from "../components/OpeningHours"
import {
  ContactsBanner,
  ParkingBanner,
  MapBanner,
  Playground,
} from "./informacie.sk"
import GallerySlider from "../components/GallerySlider"

import geoI from "../images/geo.svg"

const InformationENPage = ({ location }) => {
  const localeID = "en_US"

  return (
    <Layout location={location} localeID={localeID}>
      <Seo
        title={labels[localeID]["INFORMATION"]}
        description={labels[localeID]["INFORMATION"]}
      />
      <IntroBanner localeID={localeID} titleKey="INFORMATION" />
      <OpeningHours localeID={localeID} />
      <GallerySlider gallery="basic" className="info" />
      <MapBanner>
        <div>
          <img src={geoI} />
          <h2>{labels[localeID]["LOCATION"]}</h2>
        </div>
        <div>
          <p>
            Fresh Market
            <br />
            Rožňavská 1A
            <br />
            831 04 Bratislava
          </p>
        </div>
        <a
          href="https://goo.gl/maps/a8yYwdtxWSW8ta9J7"
          target="_blank"
          className="aArow"
        >
          {labels[localeID]["NAVIGATE"]} {rSVG("#ED6E47")}
        </a>
      </MapBanner>
      <Playground localeID={localeID} />
      <SocialSlider localeID={localeID} white={true} />
      <ParkingBanner localeID={localeID} />
      <ContactsBanner localeID={localeID} />
      <PartnersSlider className="gray" localeID={localeID} />
    </Layout>
  )
}

export default InformationENPage
